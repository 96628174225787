<template>
  <div class="container">
    <Breadcrumb />
    <Heading :content="pageTitle"></Heading>
    <hr />
    <el-row class="rowGap">
      <el-col :span="22">
        <el-input
          ref="filterInput"
          v-model="searchKeyWord"
          placeholder="Teacher name"
        />
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="setSeatchQuery">
          {{ $t("button.search") }}
        </el-button>
      </el-col>
    </el-row>
    <hr />
    <h2>Search Results</h2>
    A total of <span class="resultCount">{{ reportStatusCount }}</span> search results
    <el-table :data="teachers" :row-class-name="getRowClass">
      <el-table-column :label="$t('column.teacher')">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'userProfile',
              query: { username: scope.row.username }
            }"
          >
            {{
              userMethods.displayName(scope.row.first_name, scope.row.last_name)
            }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        label="需要完成的進度報告數"
        prop="progress_reports_needed_count"
      />
      <el-table-column
        label="已完成的進度報告數"
        prop="progress_reports_completed_count"
      />
      <el-table-column label="需要填寫進度報告的課程">
        <template slot-scope="scope">
          <span
            class="action-icon"
            @click="
              () => showCourses(scope.row.classes_without_progress_reports)
            "
          >
            <i class="fas fa-eye" />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-row class="footer">
      <el-pagination
        background
        :page-size="10"
        layout="prev, pager, next"
        :total="reportStatusCount"
        :current-page="page"
        @current-change="setCurrentPage"
      />
    </el-row>
    <el-dialog :visible.sync="coursesDialogVisible" width="500px">
      <el-table :data="courses">
        <el-table-column :label="$t('courses.course_name')">
          <template slot-scope="scope">
            <router-link :to="getCourseRouter(scope.row.session_class)">
              {{ scope.row.session_class.title }}
            </router-link>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="() => { coursesDialogVisible = false; }"
        >
          Close
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { user } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import reportApi from "@/apis/report";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - TOEFL Mocks`
    };
  },
  components: {
    Breadcrumb
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.reportStatuses");
    },
    userMethods() {
      return user;
    }
  },
  data() {
    return {
      searchKeyWord: "",
      teachers: [],
      reportStatusCount: 0,
      page: 1,
      courses: [],
      coursesDialogVisible: false
    };
  },
  mounted() {
    this.$refs.filterInput.$el.addEventListener("keydown", e => {
      if (e.code === "Enter") {
        this.setSeatchQuery();
      }
    });
  },
  created() {
    this.page = Number(this.$route.query.page || this.page);
    this.searchKeyWord = this.$route.query.searchKeyWord || this.searchKeyWord;
    this.fetchReportStatuses();
  },
  methods: {
    getCourseRouter(course) {
      const privateCourseId = [1, 2, 3, 4, 5, 6];
      const isPrivateLesson = privateCourseId.includes(
        course.course_session_id
      );
      if (isPrivateLesson) {
        return {
          name: "privateClassesDetail",
          params: { id: course.id }
        };
      } else {
        return {
          name: "classDetail",
          params: { classId: course.id }
        };
      }
    },
    showCourses(courses) {
      this.courses = courses;
      this.coursesDialogVisible = true;
    },
    setSeatchQuery() {
      this.page = 1;
      this.changeQuery();
    },
    changeQuery() {
      this.$router.push({
        name: "reportStatuses",
        query: {
          searchKeyWord: this.searchKeyWord,
          page: this.page
        }
      });
    },
    setCurrentPage(page) {
      this.page = page;
      this.changeQuery();
    },
    async fetchReportStatuses() {
      const { teacher_users } = await reportApi.fetchTeacherReportStatuses(
        this.searchKeyWord,
        this.page
      );
      this.teachers = teacher_users.data;
      this.reportStatusCount = teacher_users.total;
    },
    getRowClass({ row }) {
      const needToRemind = row.progress_reports_completed_count < row.progress_reports_needed_count;
      if (needToRemind) {
        return "remind";
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  padding-top: 1rem;
}

.resultCount {
  font-size: 24px;
  color: #3c763d;
}

::v-deep .remind {
  background-color: #fcf0f0;
}

.footer {
  margin: 20px 0px;
  text-align: center;
}
</style>
